@import '@styles/variables';

.home {
  color: $white;
  text-align: center;
  padding-top: 50px;

  a{
    color: white;
    &:hover{
      color: #c00;
    }
  }

  &__text{
    max-width: 600px;
    margin: 0 auto;
    p{
      line-height: 1.3;
    }
  }

  ul{
    margin:0;
    padding: 0;

    li{
      list-style: none;
      padding: 5px 0;
      line-height: 1.5;
      border-bottom: 1px solid #444;

      ul li{
        &:last-child{
          border: 0;
        }
      }
    }
  }
}

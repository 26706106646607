@import '@styles/variables';

.mainLayout {
  display: block;
  height: 100vh;
  overflow: hidden;
  background: radial-gradient(#4c0054, #2B002F);

  &__layers{
    height: 100vh;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    perspective: 100px;

    &__front,
    &__layer2,
    &__layer3,
    &__layer4,
    &__layer5,
    &__layer6,
    &__backLayer{

    }

    &__front{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateZ(0) scale(1);
      &__pump{
        display: block;
        position: absolute;
        bottom: -5px;
        right: 0;
        transform: translateX(55%);

         @include from-tablet-portrait-up{
           transform: translateX(30%);
        }
        @include from-desktop-up{
          transform: translateX(0);
        }
      }
    }
    &__layer2{
      transform-style: preserve-3d;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateZ(-50px) scale(1.5);

      &__pump{
        display: block;
        position: absolute;
        bottom: -2px;
        right: 0;
        transform: translateX(55%);

      @include from-tablet-portrait-up{
        transform: translateX(15%);
        }
        @include from-desktop-up{
          transform: translateX(0);
        }
      }
    }
    &__layer3{
      transform: translateZ(-100px) scale(2);
      position: absolute;
      width: 100%;
      height: 100%;
      &__house{
        position: absolute;
        bottom: -2px;
        right: 0;
        transform: translateX(25%);

        @include from-tablet-portrait-up{
          transform: translateX(15%);
        }
        @include from-desktop-up{
          transform: translateX(0);
        }
      }
    }
    &__layer4{
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateZ(-150px) scale(2.5);

      &__bat1{
        animation: 15s infinite batAni1;
        position: absolute;
        bottom: 781px;
        right: 371px;
        transform: translateX(110%);

        @include from-tablet-portrait-up{
          transform: translateX(110%);
        }
        @include from-desktop-up{
          transform: translateX(0);
        }

        @keyframes batAni1 {
          0%, 100%{
            bottom: 781px;
            right: 371px;
          }
          25%{
            bottom: 760px;
            right: 350px;
          }
          50%{
            bottom: 790px;
            right: 370px;
          }
          78%{
            bottom: 750px;
            right: 380px;
          }
        }
      }
      &__bat2{
        animation: 15s infinite batAni2;
        position: absolute;
        bottom: 730px;
        right: 618px;
        transform: translateX(457%);

        @include from-tablet-portrait-up{
          transform: translateX(457%);
        }
        @include from-desktop-up{
          transform: translateX(0);
        }

        @keyframes batAni2{
          0%, 100%{
            bottom: 730px;
            right: 618px;
          }
          25%{
            bottom: 720px;
            right: 620px;
          }
          50%{
            bottom: 735px;
            right: 600px;
          }
          78%{
            bottom: 725px;
            right: 610px;
          }
        }
      }
      &__bat3{
        animation: 15s infinite batAni3;
        position: absolute;
        bottom: 667px;
        right: 907px;

        @keyframes batAni3 {
          0%, 100% {
            bottom: 667px;
            right: 907px;
          }
          25% {
            bottom: 650px;
            right: 920px;
          }
          50% {
            bottom: 670px;
            right: 890px;
          }
          78% {
            bottom: 655px;
            right: 912px;
          }
        }
      }
      &__bat4{
        animation: 15s infinite batAni4;
        position: absolute;
        bottom: 421px;
        right: 895px;

        @keyframes batAni4 {
          0%, 100% {
            bottom: 450px;
            right: 900px;
          }
          25% {
            bottom: 470px;
            right: 850px;
          }
          50% {
            bottom: 440px;
            right: 860px;
          }
          78% {
            bottom: 410px;
            right: 888px;
          }
        }
      }
      &__bat5{
        animation: 8s infinite linear batAni5;
        position: absolute;
        bottom: 264px;
        right: 915px;
        opacity: 0;
        scale: 0.4;

        @keyframes batAni5 {
          0% {
            opacity: 0;
            scale: 0.4;
            bottom: 264px;
            right: 800px;
          }
          30% {
            opacity: 1;
          }
          60% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            scale: 1.2;
            bottom: 270px;
            right: 915px;
          }
        }
      }
    }
    &__layer5{
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateZ(-200px) scale(4);
      &__eyes{
        animation: 10s 5s infinite eyes;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-35%,-50%);

        @include from-tablet-portrait-up{
          transform: translate(-43%,-50%);
        }
        @include from-desktop-up{
          transform: translate(-50%,-50%);
        }

        @keyframes eyes {
          0%,100% {
            opacity: 0;
          }
          2% {
            opacity: 1;
          }
          98% {
            opacity: 1;
          }
        }
      }
    }
    &__layer6{
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateZ(-250px) scale(3.7);

      &__moon{
        position: absolute;
        bottom: 50px;
        right: 170px;
        transform: translateX(47%);

        @include from-tablet-portrait-up{
          transform: translateX(30%);
        }
        @include from-desktop-up{
          transform: translateX(0);
        }
      }
      &__trick{
        position: absolute;
        bottom: 376px;
        right: 446px;

        transform: translateX(118%);

        img{
          max-width: 350px;
          height: auto;
        }

        @include from-tablet-portrait-up{
          transform: translateX(65%);

          img{
            max-width: inherit;
            height: auto;
          }
        }
        @include from-desktop-up{
          transform: translateX(0);
        }
      }
    }
    &__backLayer{
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateZ(-300px) scale(4);

      &__bg_left{
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-50%);

        @include from-tablet-portrait-up{
          transform: translateX(-20%);
        }
        @include from-desktop-up{
          transform: translateX(0);
        }

        img{
          opacity: 12%;
        }
      }
      &__bg_right{
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(23%);

        @include from-tablet-portrait-up{
          transform: translateX(10%);
        }
        @include from-desktop-up{
          transform: translateX(0);
        }

        img{
          opacity: 16%;
        }
      }
    }
  }
}

.content {
  height: 1300px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #2B002F;
  z-index: 2;

}
